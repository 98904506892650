// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievement-js": () => import("./../../../src/pages/Achievement.js" /* webpackChunkName: "component---src-pages-achievement-js" */),
  "component---src-pages-case-studies-cs-dermatology-js": () => import("./../../../src/pages/CaseStudies/CS_Dermatology.js" /* webpackChunkName: "component---src-pages-case-studies-cs-dermatology-js" */),
  "component---src-pages-case-studies-cs-gynecology-js": () => import("./../../../src/pages/CaseStudies/CS_Gynecology.js" /* webpackChunkName: "component---src-pages-case-studies-cs-gynecology-js" */),
  "component---src-pages-case-studies-cs-internal-js": () => import("./../../../src/pages/CaseStudies/CS_Internal.js" /* webpackChunkName: "component---src-pages-case-studies-cs-internal-js" */),
  "component---src-pages-case-studies-cs-neurosurgery-js": () => import("./../../../src/pages/CaseStudies/CS_Neurosurgery.js" /* webpackChunkName: "component---src-pages-case-studies-cs-neurosurgery-js" */),
  "component---src-pages-case-studies-cs-ocular-js": () => import("./../../../src/pages/CaseStudies/CS_Ocular.js" /* webpackChunkName: "component---src-pages-case-studies-cs-ocular-js" */),
  "component---src-pages-case-studies-cs-orthopedics-js": () => import("./../../../src/pages/CaseStudies/CS_Orthopedics.js" /* webpackChunkName: "component---src-pages-case-studies-cs-orthopedics-js" */),
  "component---src-pages-case-studies-cs-otolaryngology-js": () => import("./../../../src/pages/CaseStudies/CS_Otolaryngology.js" /* webpackChunkName: "component---src-pages-case-studies-cs-otolaryngology-js" */),
  "component---src-pages-case-studies-cs-pediatrics-js": () => import("./../../../src/pages/CaseStudies/CS_Pediatrics.js" /* webpackChunkName: "component---src-pages-case-studies-cs-pediatrics-js" */),
  "component---src-pages-case-studies-cs-psychosomatic-js": () => import("./../../../src/pages/CaseStudies/CS_Psychosomatic.js" /* webpackChunkName: "component---src-pages-case-studies-cs-psychosomatic-js" */),
  "component---src-pages-case-studies-cs-urology-js": () => import("./../../../src/pages/CaseStudies/CS_Urology.js" /* webpackChunkName: "component---src-pages-case-studies-cs-urology-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-major-trading-manufacturer-js": () => import("./../../../src/pages/MajorTradingManufacturer.js" /* webpackChunkName: "component---src-pages-major-trading-manufacturer-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-opening-list-js": () => import("./../../../src/pages/OpeningList.js" /* webpackChunkName: "component---src-pages-opening-list-js" */),
  "component---src-pages-opening-support-js": () => import("./../../../src/pages/OpeningSupport.js" /* webpackChunkName: "component---src-pages-opening-support-js" */),
  "component---src-pages-question-and-answers-js": () => import("./../../../src/pages/QuestionAndAnswers.js" /* webpackChunkName: "component---src-pages-question-and-answers-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/Thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-third-party-succession-js": () => import("./../../../src/pages/ThirdPartySuccession.js" /* webpackChunkName: "component---src-pages-third-party-succession-js" */),
  "component---src-pages-union-overview-js": () => import("./../../../src/pages/UnionOverview.js" /* webpackChunkName: "component---src-pages-union-overview-js" */),
  "component---src-templates-details-js": () => import("./../../../src/templates/Details.js" /* webpackChunkName: "component---src-templates-details-js" */)
}

